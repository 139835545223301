import React, {useState, useEffect} from 'react';
import Web3EthContract from "web3-eth-contract";
import Web3 from "web3";
import SmartContract from "../contracts/BeeTestContract1155.json";

const contractAddress = "0x0076494bde971d5Ee47515Df62eF36636c279245"

const PriceDisplay = () => {
    const [price, setPrice] = useState();

    useEffect (()=> {
        if(window.ethereum.providers?.length)
        {
            window.ethereum.providers.forEach(async (p) => {
                Web3EthContract.setProvider(p);
                try {
                    const SmartContractObj = new Web3EthContract(
                        SmartContract.abi,
                        contractAddress
                    );
                    SmartContractObj.methods.price().call(function(err, res){
                        setPrice(res);
                        console.log(res)
                    });
                } catch (error) {console.log(error)}
            })
        }
        else {
            Web3EthContract.setProvider(window.ethereum);
            try {
                const SmartContractObj = new Web3EthContract(
                    SmartContract.abi,
                    contractAddress
                );
                SmartContractObj.methods.price().call(function(err, res){
                    setPrice(res);
                    console.log(res)
                });
            } catch (error) {console.log(error)}
        }

    }, []);

    return (
        <div>Price: {price/10**18}</div>
      );
}

export default PriceDisplay
