import logo from './logo.svg';
import './App.css';
import React, {useState} from 'react'
import WalletConnectButton from './components/WalletConnectButton';
import MintButton from './components/MintButton';
import PriceDisplay from './components/PriceDisplay';

function App() {
  //accounts state to be used across components
  const [accounts, setAccounts] = useState(null);
  const [provider, setProvider] = useState(null);

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
        </a>
        <PriceDisplay />
        <MintButton 
          accounts = {accounts}
          provider = {provider}
        />
        <WalletConnectButton
          accounts = {accounts}
          setAccounts = {setAccounts}
          setProvider = {setProvider}
        />
      </header>

    </div>
  );
}

export default App;
