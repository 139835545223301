import React, {useState} from 'react';


const WalletConnectButton = ({accounts, setAccounts, setProvider}) => {

    async function handleClick () {
      if (typeof window.ethereum !== "undefined") {
        // edge case if MM and CBW are both installed
        if (window.ethereum.providers?.length) {
          window.ethereum.providers.forEach(async (p) => {
                setAccounts(await p.request({ method: 'eth_requestAccounts' }));
                setProvider(p);
                p.on("accountsChanged", (acc) => {
                    setAccounts(acc);
                  });
                  p.on("chainChanged", () => {
                    window.location.reload();
                  });
            });
        }
        else {
          const p = window.ethereum;
          setAccounts(await p.request({ method: 'eth_requestAccounts' }));
          setProvider(p);
          p.on("accountsChanged", (acc) => {
              setAccounts(acc);
            });
            p.on("chainChanged", () => {
              window.location.reload();
            });
        }
        
      }

    }

    return (
        <button onClick={()=> handleClick ()} disabled={!!accounts}>{accounts ? `Wallet Connected` : `Connect Wallet`}</button>
      );
}

export default WalletConnectButton;